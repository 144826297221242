import React, {useState } from "react";
import TacaDePortugalM from "./img/2023_taca_masculina.png";

const MenuTacaDePortugalM = () => {
  const [open, setOpen] = useState(false);

 // Event handler for clicking the logo
 const handleLogoClick = () => {
  if (open) {
    setOpen(false);
  } else {
    setOpen(true);

    // Enviar formulário ao clicar na imagem
    const campeonato = "TPM"; 
    const fase = "."; 
    const serie = "."; 

    const form = document.getElementById("FormTPM"); 
    if (form) {
      form.querySelector('[name="campeonato"]').value = campeonato;
      form.querySelector('[name="fase"]').value = fase;
      form.querySelector('[name="serie"]').value = serie;
      form.submit();
    }
  }
};
  return (
    <div className="responsive-menu">
      <form
        id="FormTPM"
        action="https://www.portugalvoleibol.com/classificacao/tpm.php"
        method="POST"
      >
        <img
          src={TacaDePortugalM}
          alt="Logo"
          onClick={handleLogoClick}
          className="w-64 h-30 object-full"
        />
        <input type="hidden" name="campeonato" value="TPM" />
        <input type="hidden" name="fase" value="." />
        <input type="hidden" name="serie" value="." />
      </form>
    </div>
  );
};
  
  export default MenuTacaDePortugalM;