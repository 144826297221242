import React, {useState} from "react";
import { CSSTransition } from "react-transition-group";
import Supertaca from "./img/2023_supertaca.png";
import "./MenusVoleibolPavilhao.css";

const MenuSupertaca = () => {
  const [open, setOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState("main");
  const [menuHeight, setMenuHeight] = useState(null);
  const [menuVisible, setMenuVisible] = useState(false);

  // Helper function to calculate the height of the menu
  const calcHeight = (el) => {
    const height = el.scrollHeight;
    setMenuHeight(height);
  };

  // Event handler for clicking a menu item
  const handleMenuClick = (menu) => {
    setOpen(!open);
    setActiveMenu(menu);
  };

  // Event handler for clicking the logo
  const handleLogoClick = () => {
    if (open) {
      setOpen(false);
      setActiveMenu("main");
      setMenuHeight(null);
      setMenuVisible(false);
    } else {
      setOpen(true);
      setMenuVisible(true);
    }
  };
  
  return (
    <div className="responsive-menu">
      <img
        src={Supertaca}
        alt="Logo"
        onClick={handleLogoClick}
        className="w-64 h-30 object-full"
      />

      {menuVisible && (
        <div className="dropdown">
          <CSSTransition
            in={open && activeMenu === "main"}
            timeout={500}
            classNames="menu-primary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu-primary">
            <DropdownItem
              label="Masculino"
              goToMenu = "masculino"
              setActiveMenu={setActiveMenu}
            />
               <DropdownItem
              label="Feminino"
              goToMenu = "feminino"
              setActiveMenu={setActiveMenu}
            />
            </div>
          </CSSTransition>

          <CSSTransition
            in={activeMenu === "masculino"}
            timeout={500}
            classNames="menu-secondary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="main" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
              <DropdownItem
                campeonato="STM"
                fase="."
                serie="."
                label="Supertaça Masculina"
                onSubmit="true"
              />
            </div>
          </CSSTransition>

          <CSSTransition
            in={activeMenu === "feminino"}
            timeout={500}
            classNames="menu-secondary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="main" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
              <DropdownItem
                campeonato="STF"
                fase="."
                serie="."
                label="Supertaça Feminina"
                onSubmit="true"
              />
            </div>
          </CSSTransition>
        </div>
    )}
    </div>
  );
};

const DropdownItem = ({ campeonato, fase, serie, label, goToMenu, setActiveMenu, onSubmit }) => {
  const buttonRef = React.useRef(null);

  const handleClick = () => {
    if (goToMenu) {
      setActiveMenu(goToMenu);
    } else if (onSubmit) {
      const selector = `#${campeonato}-${fase}-${serie}`.replace(/[^a-zA-Z0-9-_]/g, '-');
      const form = document.querySelector(selector);
      if (form) {
        form.submit();
      }
    }
  };

  return (
    <div className="menu-item" onClick={handleClick}>
      {onSubmit ? (
        <form
          onSubmit={(e) => {
          }}
          action="https://www.portugalvoleibol.com/classificacao/classificacoes_react"
          method="POST"
        >
          <input type="hidden" name="campeonato" value={campeonato} />
          <input type="hidden" name="fase" value={fase} />
          <input type="hidden" name="serie" value={serie} />
          <button type="submit" ref={buttonRef}>
            {label}
          </button>
        </form>
      ) : (
        <button type="button" ref={buttonRef}>
          {label}
        </button>
      )}
    </div>
  );
};

  export default MenuSupertaca;