import React, {useState} from "react";
import TacaDePortugalF from "./img//2023_taca_feminina.png";
import { CSSTransition } from "react-transition-group";
import "./MenusVoleibolPavilhao.css";


const MenuTacaDePortugalF = () => {
  const [open, setOpen] = useState(false);
 
 // Event handler for clicking the logo
 const handleLogoClick = () => {
  if (open) {
    setOpen(false);
  } else {
    setOpen(true);
    

    // Enviar formulário ao clicar na imagem
    const campeonato = "TPF"; 
    const fase = "."; 
    const serie = "."; 

    const form = document.getElementById("FormTPF"); 
    if (form) {
      form.querySelector('[name="campeonato"]').value = campeonato;
      form.querySelector('[name="fase"]').value = fase;
      form.querySelector('[name="serie"]').value = serie;
      form.submit();
    }
  }
};
  return (
    <div className="responsive-menu">
      <form
        id="FormTPF" 
        action="https://www.portugalvoleibol.com/classificacao/tpf.php"
        method="POST"
      >
        <img
          src={TacaDePortugalF}
          alt="Logo"
          onClick={handleLogoClick}
          className="w-64 h-30 object-full"
        />
        <input type="hidden" name="campeonato" value="TPF" />
        <input type="hidden" name="fase" value="." />
        <input type="hidden" name="serie" value="." />
      </form>
    </div>
  );
};
  
  export default MenuTacaDePortugalF;