import React from "react";
import { Navbar, Typography } from "@material-tailwind/react";
import { useMediaQuery } from "react-responsive";

export default function Example() {
  const isMobile = useMediaQuery({ maxWidth: 1200 });

  const navbarStyle = {
    width: isMobile ? "70vw" : "1200px",
  };

  return (
    <Navbar
      className="mx-auto max-w-screen-xl px-4 py-2 grid justify-items-center bg-gradient-to-r from-neutral-500 via-neutral-300 to-neutral-300"
      style={navbarStyle}
    >
      <Typography
        as="a"
        variant="h6"
        className="text-blue-gray-900 py-1.5"
      >
        Voleibol de Pavilhão
      </Typography>
    </Navbar>
  );
}