import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import LigaUnaSeguros2 from "./img/liga_una_seguros_20242025.png";
import "./MenusVoleibolPavilhao.css";

const MenuMasculinoDivI = () => {
  const [open, setOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState("main");
  const [menuHeight, setMenuHeight] = useState(null);
  const [menuVisible, setMenuVisible] = useState(false);

  // Helper function to calculate the height of the menu
  const calcHeight = (el) => {
    const height = el.scrollHeight;
    setMenuHeight(height);
  };

  // Event handler for clicking a menu item
  const handleMenuClick = (menu) => {
    setOpen(!open);
    setActiveMenu(menu);
  };

  // Event handler for clicking the logo
  const handleLogoClick = () => {
    if (open) {
      setOpen(false);
      setActiveMenu("main");
      setMenuHeight(null);
      setMenuVisible(false);
    } else {
      setOpen(true);
      setMenuVisible(true);
    }
  };

  return (
    <div className="responsive-menu">
      <img
        src={LigaUnaSeguros2}
        alt="Logo"
        onClick={handleLogoClick}
        className="w-64 h-30 object-full"
      />

      {menuVisible && (
        <div className="dropdown">
          <CSSTransition
            in={open && activeMenu === "main"}
            timeout={500}
            classNames="menu-primary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu-primary">
              <DropdownItem
                campeonato="NSMI"
                fase="1"
                serie="."
                label="Liga Una Seguros 24/25 - 1º Fase"
                setActiveMenu={setActiveMenu}
                onSubmit="true"
              />
               <DropdownItem
                campeonato="NSMI"
                fase="2"
                serie="A"
                label="Liga Una Seguros 24/25 - 2ªFase - 1/4 Final"
                setActiveMenu={setActiveMenu}
                onSubmit="true"
              />
              <DropdownItem
                campeonato="NSMI"
                fase="2"
                serie="A2"
                label="Liga Una Seguros 24/25 - 2ªFase - 1/2 Final"
                setActiveMenu={setActiveMenu}
                onSubmit="true"
              />
                <DropdownItem
              label="Liga Una Seguros 24/25 - 2ªFase -Final"
              goToMenu = "liguna_elite"
              setActiveMenu={setActiveMenu}
            />
               <DropdownItem
              label="Liga Una Seguros 24/25 - Serie A2"
              goToMenu = "ligauna_taca"
              setActiveMenu={setActiveMenu}
            />
              <DropdownItem
                campeonato="NSMI"
                fase="2"
                serie="P1"
                label="Liga Una Seguros 24/25 - PlayOff Manutenção 1º-2ª"
                setActiveMenu={setActiveMenu}
                onSubmit="true"
              />
            </div>
          </CSSTransition>

          <CSSTransition
            in={activeMenu === "liguna_elite"}
            timeout={500}
            classNames="menu-secondary"
            unmountOnExit
            onEnter={calcHeight}
          >
  
            <div className="menu">
              <DropdownItem 
              goToMenu="main" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />{/*
              <DropdownItem
                campeonato="NSMI"
                fase="3"
                serie="#1"
                label="PlayOff #1 - 1º vs 4º"
                onSubmit="true"
              />
              <DropdownItem
                campeonato="NSMI"
                fase="3"
                serie="#2"
                label="PlayOff #2 - 2º vs 3º"
                onSubmit="true"
              />
               <DropdownItem
                campeonato="NSMI"
                fase="3"
                serie="#3"
                label="PlayOff#3 - Venc#1 vs Venc#2"
                onSubmit="true"
              />
               <DropdownItem
                campeonato="NSMI"
                fase="3"
                serie="#4"
                label="PlayOff#4 - Derr#1 vs Derr#2"
                onSubmit="true"
              />*/}
            </div>
          </CSSTransition>
          <CSSTransition
            in={activeMenu === "ligauna_taca"}
            timeout={500}
            classNames="menu-secondary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu">
              <DropdownItem 
              goToMenu="main" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />{/*
              <DropdownItem
                campeonato="NSMI"
                fase="3"
                serie="#5"
                label="PlayOff #5 - 5º clas. A vs 4º clas. A2"
                onSubmit="true"
              />
             <DropdownItem
                campeonato="NSMI"
                fase="3"
                serie="#6"
                label="PlayOff #6 - 8º clas. A vs 1º clas. A2"
                onSubmit="true"
              />
              <DropdownItem
                campeonato="NSMI"
                fase="3"
                serie="#7"
                label="PlayOff#7 - 7ºclas. A vs 2ºclas. A2"
                onSubmit="true"
              />
              <DropdownItem
                campeonato="NSMI"
                fase="3"
                serie="#8"
                label="PlayOff#8 - 6ºclas. A vs 3ºclas. A2"
                onSubmit="true"
              />
              <DropdownItem
                campeonato="NSMI"
                fase="3"
                serie="#9"
                label="PlayOff#9 - Venc #5 vs Venc #6"
                onSubmit="true"
              />
               <DropdownItem
                campeonato="NSMI"
                fase="3"
                serie="10"
                label="PlayOff#10 - Venc #7 vs Venc #8"
                onSubmit="true"
              />
               <DropdownItem
                campeonato="NSMI"
                fase="3"
                serie="11"
                label="PlayOff#11 - Venc #9 vs Venc #10"
                onSubmit="true"                                                                     
              />
               <DropdownItem
                campeonato="NSMI"
                fase="3"
                serie="12"
                label="PlayOff12 -Apuramento Campeão"
                onSubmit="true"
              />*/}
            </div>
          </CSSTransition>
        </div>
      )}
    </div>
  );
};

const DropdownItem = ({ campeonato, fase, serie, label, goToMenu, setActiveMenu, onSubmit }) => {
  const buttonRef = React.useRef(null);

  const handleClick = () => {
    if (goToMenu) {
      setActiveMenu(goToMenu);
    } else if (onSubmit) {
      const selector = `#${campeonato}-${fase}-${serie}`.replace(/[^a-zA-Z0-9-_]/g, '-');
      const form = document.querySelector(selector);
      if (form) {
        form.submit();
      }
    }
  };

  return (
    <div className="menu-item" onClick={handleClick}>
      {onSubmit ? (
        <form
          onSubmit={(e) => {
          }}
          action="https://www.portugalvoleibol.com/classificacao/classificacoes_react"
          method="POST"
        >
          <input type="hidden" name="campeonato" value={campeonato} />
          <input type="hidden" name="fase" value={fase} />
          <input type="hidden" name="serie" value={serie} />
          <button type="submit" ref={buttonRef}>
            {label}
          </button>
        </form>
      ) : (
        <button type="button" ref={buttonRef}>
          {label}
        </button>
      )}
    </div>
  );
};

export default MenuMasculinoDivI;
	
