import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MenuPavilhao from './Menus/VoleibolPavilhão/MenuPavilhao'
import Footer from './Footer/Footer'
import MenuVoleibolPraia from './Menus/VoleibolPraia/MenuVoleibolPraia'
import MenuFormacao from './Menus/Formação/MenuFormacao'
import MasculinoDivI from './Menus/VoleibolPavilhão/MasculinoDivI.jsx'
import FemininoDivI from './Menus/VoleibolPavilhão/FemininoDivI'
import MenuDivII from './Menus/VoleibolPavilhão/divisaoII'
import MenuDivIII from './Menus/VoleibolPavilhão/divisaoIII'
import MenuVeteranos from './Menus/VoleibolPavilhão/veteranos'
import MenuVeteranosTF from './Menus/VoleibolPavilhão/veteranosTF.jsx'
import MenuSuperTaca from './Menus/VoleibolPavilhão/supertaca'
import MenuTacaPortugalVoleibolM from './Menus/VoleibolPavilhão/tacadeportugalM'
import MenuTacaPortugalVoleibolF from './Menus/VoleibolPavilhão/tacadeportugalF'
import MenuCampeonatoNacionalDeClubes from './Menus/VoleibolPraia/campeonatonacionaldeclubes'
import MenuCampeonatoNacionalClubesVeteranos from './Menus/VoleibolPraia/campeonatoNacionalDeClubesVeteranos';
import MenuIniciados from './Menus/Formação/iniciados'
import MenuInfantis from './Menus/Formação/infantis'
import MenuCadetes from './Menus/Formação/cadetes'
import MenuJuvenis from './Menus/Formação/juvenis'
import MenuJuniores from './Menus/Formação/juniores'
import MenuSub21JB from './Menus/Formação/sub21(jb)'
import MenuSub21JB1 from './Menus/Formação/sub21(jb1)'
import Navbar from "./Navbar/Navbar"
import LastAndNextMatches from './LastAndNextNatches/LastAndNextMatches.jsx';
import MenuIniciadosA from './Menus/Formação/iniciadosA';
import MenuCadetesA from './Menus/Formação/cadetesA.jsx';
import MenuJuvenisA from './Menus/Formação/juvenisA.jsx';
import MenuJunioresA from './Menus/Formação/junioresA.jsx';


function App() {
  return (
    <Router>
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow">
        <div className="">
          <Navbar/>
        </div>
        <Routes>
            <Route path="/LastAndNextMatches" element={<LastAndNextMatches />} />
          </Routes>
        <div className="flex flex-col items-center justify-center">
          <div className="my-12 inline-block">
            <MenuPavilhao />
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-8">
              <div>
                <MasculinoDivI/>
              </div>
              <div>
                <FemininoDivI/>
              </div>
              <div>
                <MenuDivII />
              </div>
              <div>
                <MenuDivIII />
              </div>
              <div>
                <MenuVeteranos />
              </div>
              <div>
                <MenuVeteranosTF/>
              </div>
              <div>
                <MenuSuperTaca />
              </div>
              <div>
                <MenuTacaPortugalVoleibolM />
              </div>
              <div>
                <MenuTacaPortugalVoleibolF />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center">
            <div className="my-12 inline-block">
              <MenuFormacao />
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-8">
                <div>
                  <MenuInfantis />
                </div>
                <div>
                  <MenuIniciados />
                </div>
                <div>
                  <MenuIniciadosA/>
                </div>
                <div>
                  <MenuCadetes />
                </div>
                <div>
                  <MenuCadetesA />
                </div>
                <div>
                  <MenuJuvenis />
                </div>
                <div>
                  <MenuJuvenisA/>
                </div>
                <div>
                  <MenuJuniores />
                </div>
                <div>
                  <MenuJunioresA/>
                </div>
                <div>
                  <MenuSub21JB />
                </div>
                <div>
                  <MenuSub21JB1 />
                </div>
              </div>
            </div>
          </div>
          <div>
           {/* <div className="flex flex-col items-center justify-center">
            <div className="my-12 inline-block">  
                <MenuVoleibolPraia />
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-8">
                  <MenuCampeonatoNacionalDeClubes />
                  <MenuCampeonatoNacionalClubesVeteranos/>
                  </div>
              </div>
            </div>*/}
          </div>
        </div>
        <div>
          <Footer />
        </div>
      </div>
      </div>
    </Router>
  );
}

export default App;

