import React, {useState} from "react";
import { CSSTransition } from "react-transition-group";
import Veteranos from "./img/trofeu_federacao_veteranos.png";
import "./MenusVoleibolPavilhao.css";

const MenuVeteranosTF = () => {
  const [open, setOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState("main");
  const [menuHeight, setMenuHeight] = useState(null);
  const [menuVisible, setMenuVisible] = useState(false);

  // Helper function to calculate the height of the menu
  const calcHeight = (el) => {
    const height = el.scrollHeight;
    setMenuHeight(height);
  };

  // Event handler for clicking a menu item
  const handleMenuClick = (menu) => {
    setOpen(!open);
    setActiveMenu(menu);
  };

  // Event handler for clicking the logo
  const handleLogoClick = () => {
    if (open) {
      setOpen(false);
      setActiveMenu("main");
      setMenuHeight(null);
      setMenuVisible(false);
    } else {
      setOpen(true);
      setMenuVisible(true);
    }
  };

    return (
      <div className="responsive-menu">
        <img
          src={Veteranos}
          alt="Logo"
          onClick={handleLogoClick}
          className="w-64 h-30 object-full"
        />
  
        {menuVisible && (
          <div className="dropdown">
            <CSSTransition
              in={open && activeMenu === "main"}
              timeout={500}
              classNames="menu-primary"
              unmountOnExit
              onEnter={calcHeight}
            >
              <div className="menu-primary">
              <DropdownItem
              label="Masculino"
              goToMenu = "masculino"
              setActiveMenu={setActiveMenu}
            />
               <DropdownItem
              label="Feminino"
              goToMenu = "feminino"
              setActiveMenu={setActiveMenu}
            />
              </div>
            </CSSTransition>
  
            <CSSTransition
              in={activeMenu === "masculino"}
              timeout={500}
              classNames="menu-secondary"
              unmountOnExit
              onEnter={calcHeight}
            >
              <div className="menu">
              <DropdownItem 
              goToMenu="main" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
              <DropdownItem
                campeonato="NVETAM"
                fase="3"
                serie="#1"
                label="Veteranos A Masculinos"
                onSubmit="true"
              />
              <DropdownItem
                campeonato="NVETBM"
                fase="3"
                serie="#2"
                label="Veteranos B Masculinos"
                onSubmit="true"
              />
              <DropdownItem
                campeonato="NVETCM"
                fase="3"
                serie="#3"
                label="Veteranos C Masculinos"
                onSubmit="true"
              />
              </div>
            </CSSTransition>
  
            <CSSTransition
              in={activeMenu === "feminino"}
              timeout={500}
              classNames="menu-secondary"
              unmountOnExit
              onEnter={calcHeight}
            >
              <div className="menu">
              <DropdownItem 
              goToMenu="main" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
              <DropdownItem
                campeonato="NVETAF"
                fase="3"
                serie="#4"
                label="Veteranos A Femininos"
                onSubmit="true"
              />
              <DropdownItem
                campeonato="NVETBF"
                fase="3"
                serie="#5"
                label="Veteranos B Femininos"
                onSubmit="true"
              />
              </div>
            </CSSTransition>
          </div>
        )}
      </div>
    );
  };

  const DropdownItem = ({ campeonato, fase, serie, label, goToMenu, setActiveMenu, onSubmit }) => {
    const buttonRef = React.useRef(null);
  
    const handleClick = () => {
      if (goToMenu) {
        setActiveMenu(goToMenu);
      } else if (onSubmit) {
        const selector = `#${campeonato}-${fase}-${serie}`.replace(/[^a-zA-Z0-9-_]/g, '-');
        const form = document.querySelector(selector);
        if (form) {
          form.submit();
        }
      }
    };
  
    return (
      <div className="menu-item" onClick={handleClick}>
        {onSubmit ? (
          <form
            onSubmit={(e) => {
            }}
            action="https://www.portugalvoleibol.com/classificacao/classificacoes_react"
            method="POST"
          >
            <input type="hidden" name="campeonato" value={campeonato} />
            <input type="hidden" name="fase" value={fase} />
            <input type="hidden" name="serie" value={serie} />
            <button type="submit" ref={buttonRef}>
              {label}
            </button>
          </form>
        ) : (
          <button type="button" ref={buttonRef}>
            {label}
          </button>
        )}
      </div>
    );
  };

  export default MenuVeteranosTF;