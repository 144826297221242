import React from "react";
import solverde from "./img/solverde.png";
import sport_tv from "./img/sporttv.png";
import aff_logo from "./img/affsports.png";
import unaseguros from "./img/unaseguros.png"
import fivb from "./img/fivb.png";
import cev from "./img/cev.png";
import cop from "./img/cop.png";
import ipdj from "./img/ipdj.png";
import pned from "./img/pned.png";
import volei_tv from "./img/voleitv.png"
import kombucha from "./img/kombucha.png"

function Footer() {
	return (
         <>
			<div className="bg-gradient-to-r from-neutral-500 via-neutral-300 to-neutral-300 h-1/2 w-full flex md:flex-row flex-col justify-around p-2 opacity-80">
				<div className="p-2 ">
					<ul>
						<p className="text-white font-bold text-2xl pb-4"></p>
						<li className="text-white text-md font-semibold">
                        FEDERAÇÃO PORTUGUESA DE VOLEIBOL
                            <li>
                            Avenida da França, 549
                            <li>
                            4050-279 Porto, Portugal
                             </li>
                            </li>
						</li>
						<li className="text-white text-md font-semibold">
                        Tel: (+351)228349570
						</li>
						<li className="text-white text-md font-semibold ">
                        Fax: (+351)228325494
						</li>
						<li className="text-white text-md font-semibold">
                        Email: fpvoleibol@fpvoleibol.pt
						</li>
					</ul>
				</div>
				<div className="p-2 ">
					<ul>
						<p className="text-white font-bold text-2xl pb-4"></p>
						<div className="grid gap-4 grid-cols-3">
						<span>
						<a href="https://www.sporttv.pt/">
						<img src={sport_tv} alt="sport_tv" className="h-16"/>
						</a>
						</span>
					<span>
					<a href="https://www.solverde.pt">
                         <img src={solverde} alt="solverde" className="h-16"/>
						 </a>
                    </span>
                    <span >
					<a href="https://www.affsports.pt/">
                         <img src={aff_logo} alt="aff_logo" className="h-16"/>
						 </a>
                    </span>
					{/*<span>
						<a href="https://www.volei.tv/">
                         <img src={volei_tv} alt="volei_tv" className="h-16"/>
						 </a>
						 </span>*/}
					<span >
					<a href="https://unaseguros.pt/">
                         <img src={unaseguros} alt="unaseguros" className="h-16"/>
						 </a>
                    </span>
					<span >
					<a href="https://www.liptonicetea.pt/home.html">
                         <img src={kombucha} alt="kombucha" className="h-16"/>
						 </a>
                    </span>
						</div>
					</ul>
				</div>
				<div className="p-2 ">
					<ul>
						<p className="text-white font-bold text-2xl pb-4"></p>
						<div className="grid gap-4 grid-cols-3 cursor-pointer">
						<a href="https://www.fivb.com/en">
   						 	<img src={fivb} alt="fivb" className="h-16"/>
						</a>
                            <span > 
							<a href="https://www.cev.eu/">  
                                <img src={cev} alt="cev" className="h-16"/> 
								</a>
                            </span>
                            <span >
							<a href="https://comiteolimpicoportugal.pt/"> 
                                <img src={cop} alt="cop" className="h-16"/>
								</a>	
                            </span>
                            <span>
							<a href="https://ipdj.gov.pt/"> 
                                 <img src={ipdj} alt="ipdj" className="h-16"/>
								 </a>
                            </span>
                            <span >
							<a href="https://pned.ipdj.gov.pt/"> 
                                <img src={pned} alt="etica_desporto" className="h-16"/>
								</a>
                            </span>
						</div>
					</ul>
				</div>
			</div>

			<div className="bg-gradient-to-r from-neutral-500 via-neutral-300 to-neutral-300 justify-center items-center text-center opacity-80 p-1 w-full">
        <h1 className="text-white font-semibold">
                © 2023 Federação Portuguesa de Voleibol – Todos os direitos reservados 
				</h1>
			</div>
		</>
	);
}

export default Footer;