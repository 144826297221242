import React, { useState } from "react";
import { Navbar, Typography } from "@material-tailwind/react";
import LogoFPVoleibol from './img/logotipo_fpvoleibol_256.png';
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';

function NavbarFPV() {
  const navigate = useNavigate();
  const [selectedEpoca, setSelectedEpoca] = useState("");
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' }); // Adjust the max-width as needed

  const handleEpocaChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedEpoca(selectedValue);
    // Redirecione para a URL correspondente
    switch (selectedValue) {
      case "2024-2025":
        window.location.href = "https://www.portugalvoleibol.com/classificacao/";
        break;
      case "2023-2024":
        window.location.href = "https://www.portugalvoleibol.com/classificacao/2023";
        break;
      case "2022-2023":
        window.location.href = "https://www.portugalvoleibol.com/classificacao/2022/";
        break;
      case "2021-2022":
        window.location.href = "https://www.portugalvoleibol.com/classificacao/2021/";
        break;
      case "2020-2021":
        window.location.href = "https://www.portugalvoleibol.com/classificacao/2020/";
        break;
      case "2019-2020":
        window.location.href = "https://www.portugalvoleibol.com/classificacao/2019/";
        break;
      case "2018-2019":
        window.location.href = "https://www.portugalvoleibol.com/classificacao/2018/";
        break;
      default:
        break;
    }
  };

  const handleLastNextMatchesClick = () => {
    navigate('/LastAndNextMatches');
  };

  const selectContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: isMobile ? '1rem' : 0,
  };

  const selectStyle = {
    width: isMobile ? '43vw' : 'auto',
  };

  const selectContainerClass = isMobile ? 'flex justify-center items-center' : 'sm:absolute right-4 bottom-2 mt-4';

  return (
    <div className="flex justify-center items-center opacity-90">
      <Navbar className="m-10 relative">
        <a href="https://fpvoleibol.pt/fpv/" className="flex flex-col items-center text-lg font-bold leading-relaxed mb-4 py-2 whitespace-nowrap uppercase">
          <img src={LogoFPVoleibol} alt="Logo FPVoleibol" className="h-24 mt-8 transition-transform hover:scale-110" />
          <span className="mt-2 transition-transform hover:scale-110" style={{ color: "#0C54A0" }}>
            Federação Portuguesa De Voleibol
          </span>
        </a>
        <Typography as="a" variant="h6" className="mx-auto max-w-screen-xl grid justify-items-center text-blue-gray-900 text-2xl font-bold">
          Competições Nacionais
        </Typography>
        <div className= {`relative sm:absolute right-4 bottom-2 mt-4 sm:mt-0 ${selectContainerClass}`}>
        <div className="relative h-10 w-46 min-w-[100px]"style={selectContainerStyle}>
          <select
            className="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-blue -500 focus:border-2 focus:border-blue-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 sm:w-full sm:inline-block text-center"
            value={selectedEpoca}
            onChange={handleEpocaChange}
            style={selectStyle}
          >
              <option value="2024-2025">Época 2024-2025</option>
              <option value="2023-2024">Época 2023-2024</option>
              <option value="2022-2023">Época 2022-2023</option>
              <option value="2021-2022">Época 2021-2022</option>
              <option value="2020-2021">Época 2020-2021</option>
              <option value="2019-2020">Época 2019-2020</option>
              <option value="2018-2019">Época 2018-2019</option>
            </select>
            <label
              className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-blue-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-blue-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-blue-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 sm:w-full">
              Selecione uma Época
            </label>
          </div>
        </div>
        {/*<button
          onClick={handleLastNextMatchesClick}
          className="absolute bottom-2 left-4 p-2 text-white rounded-full shadow-md hover: transition duration-300"
        >
          Próximos e Últimos Jogos
  </button>*/}
      </Navbar>
    </div>
  );
}

export default NavbarFPV;