import React, { useState, useEffect } from 'react';

const App = () => {
  const [nextMatches, setNextMatches] = useState([]);
  const [lastMatches, setLastMatches] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const user = "fpv%40dataproject.com";
  const secret = "WCM2017";

  // Função para obter o token de acesso
  const getAccessToken = async () => {
    try {
      const response = await fetch("https://dataprojectidentityserver.azurewebsites.net/core/connect/token", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `grant_type=password&username=${user}&password=*2F!p0V&scope=openid%20email%20wcmapiservice&client_id=WCM.Federation.client&client_secret=${secret}&undefined=`,
      });

      if (!response.ok) {
        throw new Error('Failed to get access token');
      }

      const data = await response.json();
      return data.access_token;
    } catch (error) {
      console.error('Error getting access token:', error);
      return null;
    }
  };

  // Função para buscar os próximos jogos
  const fetchNextMatches = async (accessToken) => {
    try {
      const response = await fetch("https://dataprojectserviceswebapilive.azurewebsites.net/api/v1/FPV/MatchCalendar", {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch next matches data');
      }

      const data = await response.json();
      setNextMatches(data);
    } catch (error) {
      console.error('Error fetching next matches:', error);
    }
  };

  // Função para buscar os últimos jogos
  const fetchLastMatches = async (accessToken) => {
    try {
      const response = await fetch("https://dataprojectserviceswebapilive.azurewebsites.net/api/v1/FPV/LastMatches", {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch last matches data');
      }

      const data = await response.json();
      setLastMatches(data);
    } catch (error) {
      console.error('Error fetching last matches:', error);
    }
  };

  useEffect(() => {
    const fetchMatches = async () => {
      const accessToken = await getAccessToken();
      if (accessToken) {
        await Promise.all([fetchNextMatches(accessToken), fetchLastMatches(accessToken)]);
        setIsLoading(false);
      }
    };

    fetchMatches();
  }, []);

  return (
    <div>
      <h1>Volleyball Matches</h1>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <>
          <Tabs>
            <Tab title="Next Matches">
              <MatchList matches={nextMatches} />
            </Tab>
            <Tab title="Last Matches">
              <MatchList matches={lastMatches} />
            </Tab>
          </Tabs>
        </>
      )}
    </div>
  );
};

const Tabs = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div>
      <div className="tabs">
        {React.Children.map(children, (child, index) => (
          <button
            key={index}
            className={index === activeTab ? 'active' : ''}
            onClick={() => setActiveTab(index)}
          >
            {child.props.title}
          </button>
        ))}
      </div>
      <div className="tab-content">
        {React.Children.toArray(children)[activeTab]}
      </div>
    </div>
  );
};

const Tab = ({ children }) => {
  return <>{children}</>;
};

const MatchList = ({ matches }) => {
  return (
    <ul>
      {matches.map(match => (
        <li key={match.MatchID}>
          <p>Match ID: {match.MatchID}</p>
          <p>Home Team: {match.HomeTeam}</p>
          <p>Guest Team: {match.GuestTeam}</p>
          {/* Adicione mais campos conforme necessário */}
        </li>
      ))}
    </ul>
  );
};

export default App;